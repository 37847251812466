<template>
  <a-row>
    <a-col :span="8">
      <a-descriptions
        :column="1"
        class="iotplt-descriptions iotplt-descriptions-max-6"
      >
        <a-descriptions-item label="入库批次">
          {{ batchOperationRecordInfo.operation_no }}
        </a-descriptions-item>
        <a-descriptions-item label="入库时间">
          {{ batchOperationRecordInfo.created_at }}
        </a-descriptions-item>
        <a-descriptions-item label="入库人">
          {{ batchOperationRecordInfo.operator_name }}
        </a-descriptions-item>
        <a-descriptions-item label="入库总数">
          {{ batchOperationRecordInfo.operation_count }}
        </a-descriptions-item>
        <a-descriptions-item label="入库成功数">
          {{ batchOperationRecordInfo.success_count }}
        </a-descriptions-item>
        <a-descriptions-item label="入库失败数">
          {{ batchOperationRecordInfo.failure_count }}
        </a-descriptions-item>
        <a-descriptions-item label="重跑次数">
          {{ batchOperationRecordInfo.run_times }}
        </a-descriptions-item>
        <a-descriptions-item label="备注">
          <span class="iotplt-word-break-all">
            &nbsp;
          </span>
        </a-descriptions-item>
      </a-descriptions>
    </a-col>

    <a-col :span="8">
      <a-descriptions
        :column="1"
        class="iotplt-descriptions iotplt-descriptions-max-8"
      >
        <a-descriptions-item label="运营商">
          {{ batchOperationRecordInfo.carrier_type }}
        </a-descriptions-item>
        <a-descriptions-item label="用户业务模式">
          {{ batchOperationRecordInfo.user_business_type }}
        </a-descriptions-item>
        <a-descriptions-item label="开卡套餐">
          {{ batchOperationRecordInfo.product }}
        </a-descriptions-item>
        <a-descriptions-item label="服务周期">
          {{ batchOperationRecordInfo.service_cycle }}
        </a-descriptions-item>
        <a-descriptions-item label="是否需要实名认证">
          {{ batchOperationRecordInfo.is_need_verified }}
        </a-descriptions-item>
        <a-descriptions-item label="是否限额">
          {{ batchOperationRecordInfo.is_limit_usage }}
        </a-descriptions-item>
        <a-descriptions-item v-if="batchOperationRecordInfo.is_limit_usage_slug" label="限额值">
          {{ batchOperationRecordInfo.limit_usage_value }}
        </a-descriptions-item>
        <a-descriptions-item label="是否可续期订购">
          {{ batchOperationRecordInfo.renewable }}
        </a-descriptions-item>
      </a-descriptions>
    </a-col>

    <a-col :span="8">
      <a-descriptions
        :column="1"
        class="iotplt-descriptions iotplt-descriptions-max-7"
      >
        <a-descriptions-item label="沉默期(月)">
          {{ batchOperationRecordInfo.silent_period }}
        </a-descriptions-item>
        <a-descriptions-item label="沉默期截止时间">
          {{ batchOperationRecordInfo.silent_period_end_date }}
        </a-descriptions-item>
      </a-descriptions>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'BatchOperationRecordAllocateCardsToTopAgentInfo',
  props: {
    batchOperationRecordInfo: {
      type: Object,
      required: true
    }
  }
}
</script>
